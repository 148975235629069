/* eslint-disable max-len */
<template>
	<div>
		<template v-if="loading">
			<div class="div__loading">
				<Loader />
			</div>
		</template>
		<template v-else>
			<section class="main-grid__row user-profile">
				<div class="user-profile__card">
					<div class="user-profile__section">
						<h5 class="user-profile__heading">Basic Information</h5>

						<div class="user-profile__item">
							<p class="user-profile__section__title">Amount:</p>
							<p class="" id="orderAmount">&#8358; {{ itemData.amount }}</p>
						</div>

						<div class="user-profile__item">
							<p class="user-profile__section__title">Balance:</p>
							<p class="" id="orderAmount">&#8358; {{ itemData.amount }}</p>
						</div>

						<div class="user-profile__item">
							<p class="user-profile__section__title">Payment Method:</p>
							<p class="capital">
								{{ itemData.payment }}
							</p>
						</div>

						<div class="user-profile__item">
							<p class="user-profile__section__title">Status</p>
							<p>
								{{ itemData.status }}
							</p>
						</div>

						<div class="user-profile__item">
							<p class="user-profile__section__title">Created Date</p>
							<p id="date">
								{{ itemData.created_at | date }}
							</p>
						</div>

						<div class="user-profile__item">
							<p class="user-profile__section__title">Withdrawal Date</p>
							<p id="date">
								{{ itemData.withdraw_date | date }}
							</p>
						</div>
					</div>
				</div>
				<div class="user-profile__tabs" ref="tab">
					<ul class="nav nav-tabs" id="myTab" role="tablist">
						<li class="nav-item">
							<a
								class="nav-link active"
								id="profile-tab"
								data-toggle="tab"
								href="#profile"
								role="tab"
								aria-controls="profile"
								aria-selected="true"
								>Details</a
							>
						</li>
					</ul>
					<div class="tab-content" id="myTabContent">
						<div
							class="tab-pane fade show active user-profile__tabs__section"
							id="profile"
							role="tabpanel"
							aria-labelledby="profile-tab"
						>
							<div class="settings">
								<form>
									<div class="settings__field">
										<div class="form-row">
											<div class="form-group col-md-12">
												<div class="settings__value">
													<label for="buyer">Saving Owner Name</label>
													<input
														@keydown="clearErrors($event.target.name)"
														v-model="fullName"
														name="owner"
														type="text"
														class="form-control capital"
														id="owner"
														disabled
													/>
												</div>
											</div>
											<div class="form-group col-md-12">
												<div class="settings__value">
													<label for="nam">Saving Title</label>
													<input
														:class="[{ 'is-invalid': errors.buyer }]"
														@keydown="clearErrors($event.target.name)"
														v-model="itemData.name"
														name="name"
														type="text"
														class="form-control"
														id="buyer"
														disabled
													/>
												</div>
											</div>
											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="saving_appreciation">Saving Appreciation</label>
													<p>&#8358; {{ itemData.saving_appreciation }}</p>
												</div>
											</div>
											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="returns">Returns:</label>
													<p>{{ itemData.returns }}%</p>
												</div>
											</div>
											<div class="form-group col-md-6" v-if="itemData.is_recurrent === 1">
												<div class="settings__value">
													<label for="cycle">Saving Type</label>
													<div class="settings__value">
														<p>Automated</p>
													</div>
												</div>
											</div>
											<div class="form-group col-md-6" v-else>
												<div class="settings__value">
													<label for="cycle">Saving Type</label>
													<div class="settings__value">
														<p>Non Automated</p>
													</div>
												</div>
											</div>

											<div class="form-group col-md-6" v-if="itemData.saving_cycle !== null">
												<div class="settings__value">
													<label for="cycle">Saving Cycle</label>
													<p>{{ itemData.saving_cycle }}</p>
												</div>
											</div>

											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="duratuion">Saving Duration</label>
													<p>{{ itemData.duration }} Months</p>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
		</template>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import form from '../../mixins/form';
import Loader from '../../components/Loader';

export default {
	name: 'SavingDetails',
	mixins: [form],
	data() {
		return {
			itemData: {},
			errors: {},
			loading: true
		};
	},
	created() {
		this.fetchSavingsDetail(this.$route.params.id);
	},
	watch: {
		getStatus(value) {
			if (value === 'success') {
				this.itemData = this.getSavingsDetail;
				this.loading = false;
			}
		}
	},
	computed: {
		...mapGetters(['getStatus', 'getSavingsDetail']),
		fullName() {
			return `${this.itemData.user.firstname} ${this.itemData.user.lastname}`;
		}
	},
	methods: {
		...mapActions(['fetchSavingsDetail'])
	},
	components: {
		Loader
	},
	filters: {
		date(val) {
			return moment(val).format('LLLL');
		}
	}
};
</script>

<style lang="scss" scoped>
.settings {
	&--wide {
		max-width: 100%;
	}
	&__field {
		margin-top: 30px;
	}
	&__value {
		padding: 0;
		background: #fff;
		input {
			background: #fafafa;
			border: none;
			font-size: 14px;
			height: 36px;
		}
		label {
			font-weight: bold;
			margin-bottom: 0;
		}
	}
	.row {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 2rem;
	}
}
.btn__wrapper {
	margin-top: 20px;
	.btn {
		&:not(:last-child) {
			margin-right: 10px;
		}
	}
}
.bold {
	font-weight: bold;
	font-size: 16px;
	margin-right: 5px;
}
.pad {
	padding: 5px 10px;
}

.capital {
	text-transform: capitalize;
}
</style>
