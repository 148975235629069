/* eslint-disable max-len */
<template>
	<div>
		<template v-if="loading">
			<div class="div__loading">
				<Loader />
			</div>
		</template>
		<template v-else>
			<section class="main-grid__row user-profile">
				<div class="user-profile__card">
					<div class="user-profile__section">
						<h5 class="user-profile__heading">Basic Information</h5>

						<div class="user-profile__item">
							<p class="user-profile__section__title">Currency:</p>
							<p id="orderCurrency">{{ itemData.currency }}</p>
						</div>

						<div class="user-profile__item">
							<p class="user-profile__section__title">Amount:</p>
							<p class="" id="orderAmount">
								{{ itemData.amount | currencyFormatter(itemData.currency) }}
							</p>
						</div>

						<div class="user-profile__item">
							<p class="user-profile__section__title">Charged Amount:</p>
							<p class="" id="orderAmount">
								{{ itemData.charged_amount | currencyFormatter(itemData.currency) }}
							</p>
						</div>

						<div class="user-profile__item">
							<p class="user-profile__section__title">Status</p>
							<p>
								{{ itemData.status }}
							</p>
						</div>

						<div class="user-profile__item">
							<p class="user-profile__section__title">Date</p>
							<p id="date">
								{{ itemData.created_at | date }}
							</p>
						</div>
					</div>
				</div>
				<div class="user-profile__tabs" ref="tab">
					<ul class="nav nav-tabs" id="myTab" role="tablist">
						<li class="nav-item">
							<a
								class="nav-link active"
								id="profile-tab"
								data-toggle="tab"
								href="#profile"
								role="tab"
								aria-controls="profile"
								aria-selected="true"
							>
								Details
							</a>
						</li>
					</ul>
					<div class="tab-content" id="myTabContent">
						<div
							class="tab-pane fade show active user-profile__tabs__section"
							id="profile"
							role="tabpanel"
							aria-labelledby="profile-tab"
						>
							<div class="settings">
								<form>
									<div class="settings__field">
										<div class="form-row">
											<div class="form-group col-md-12">
												<div class="settings__value">
													<label for="buyer">Buyer</label>
													<input
														:class="[{ 'is-invalid': errors.buyer }]"
														@keydown="clearErrors($event.target.name)"
														v-model="fullName"
														name="buyer"
														type="text"
														class="form-control"
														id="buyer"
														disabled
													/>
												</div>
											</div>
											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="quantity">Quantity</label>
													<input
														:class="[{ 'is-invalid': errors.currency }]"
														@keydown="clearErrors($event.target.name)"
														v-model="itemData.quantity"
														name="quantity"
														type="text"
														class="form-control"
														id="quantity"
														disabled
													/>
												</div>
											</div>
											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="payment">Payment</label>
													<input
														:class="[{ 'is-invalid': errors.payment }]"
														@keydown="clearErrors($event.target.name)"
														v-model="itemData.payment"
														name="payment"
														type="text"
														class="form-control capital"
														id="payment"
														disabled
													/>
												</div>
											</div>
											<div class="form-group col-md-12">
												<div class="settings__value">
													<label for="order_number">Order Number</label>
													<input
														:class="[{ 'is-invalid': errors.currency }]"
														@keydown="clearErrors($event.target.name)"
														v-model="itemData.order_number"
														name="order_number"
														type="text"
														class="form-control"
														id="order_number"
														disabled
													/>
												</div>
											</div>

											<div
												class="form-group col-md-12"
												v-if="itemData.dw_status_message !== null"
											>
												<div class="settings__value">
													<label for="dw_status_message">Drivewealth Status</label>
													<input
														:class="[{ 'is-invalid': errors.dw_status_message }]"
														@keydown="clearErrors($event.target.name)"
														v-model="itemData.dw_status_message"
														name="dw_status_message"
														type="text"
														class="form-control"
														id="dw_status_message"
														disabled
													/>
												</div>
											</div>

											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="symbol">Stock Symbol</label>
													<input
														:class="[{ 'is-invalid': errors.symbol }]"
														@keydown="clearErrors($event.target.name)"
														v-model="itemData.instrument.symbol"
														name="symbol"
														type="text"
														class="form-control"
														id="symbol"
														disabled
													/>
												</div>
											</div>

											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="stockName">Stock Name</label>
													<input
														:class="[{ 'is-invalid': errors.name }]"
														@keydown="clearErrors($event.target.name)"
														v-model="itemData.instrument.name"
														name="stockName"
														type="text"
														class="form-control"
														id="stockName"
														disabled
													/>
												</div>
											</div>

											<div class="form-group col-md-6" v-if="itemData.instrument.sector !== null">
												<div class="settings__value">
													<label for="stockStock">Stock Sector</label>
													<input
														@keydown="clearErrors($event.target.name)"
														v-model="itemData.instrument.sector"
														name="stockStock"
														type="text"
														class="form-control"
														id="stockStock"
														disabled
													/>
												</div>
											</div>

											<div
												class="form-group col-md-12"
												v-if="itemData.instrument.description !== null"
											>
												<div class="settings__value">
													<label for="stockDescription">Stock Description</label>
													<input
														@keydown="clearErrors($event.target.name)"
														v-model="itemData.instrument.description"
														name="stockDescription"
														type="text"
														class="form-control"
														id="stockDescription"
														disabled
													/>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
		</template>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import form from '../../mixins/form';
import Loader from '../../components/Loader';

export default {
	name: 'OrderDetails',
	mixins: [form],
	data() {
		return {
			itemData: {},
			errors: {},
			loading: true
		};
	},
	created() {
		this.fetchOrderDetail(this.$route.params.id);
	},
	watch: {
		getStatus(value) {
			if (value === 'success') {
				this.itemData = this.getOrderDetail;
				this.loading = false;
			}
		}
	},
	computed: {
		...mapGetters(['getStatus', 'getOrderDetail']),
		fullName() {
			return `${this.itemData.user.firstname} ${this.itemData.user.lastname}`;
		}
	},
	methods: {
		...mapActions(['fetchOrderDetail'])
	},
	components: {
		Loader
	},
	filters: {
		date(val) {
			return moment(val).format('LLLL');
		},
		currencyFormatter(val, currency) {
			const formatter = new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency,
				minimumFractionDigits: 2
			});
			return formatter.format(val);
		}
	}
};
</script>

<style lang="scss" scoped>
.settings {
	&--wide {
		max-width: 100%;
	}
	&__field {
		margin-top: 30px;
	}
	&__value {
		padding: 0;
		background: #fff;
		input {
			background: #fafafa;
			border: none;
			font-size: 14px;
			height: 36px;
		}
		label {
			font-weight: bold;
			margin-bottom: 0;
		}
	}
	.row {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 2rem;
	}
}
.btn__wrapper {
	margin-top: 20px;
	.btn {
		&:not(:last-child) {
			margin-right: 10px;
		}
	}
}
.bold {
	font-weight: bold;
	font-size: 16px;
	margin-right: 5px;
}
.pad {
	padding: 5px 10px;
}

.capital {
	text-transform: capitalize;
}
</style>
