<template>
	<div>
		<template v-if="loadingInvestorMutualFunds">
			<div class="div__loading">
				<Loader />
			</div>
		</template>
		<template v-else>
			<div class="main__header">
				<div class="main__primary">
					<h3>Investor Mutual Funds</h3>
				</div>
			</div>
			<section class="main-grid__row contacts contacts--list">
				<div class="contacts--list__wrapper">
					<div class="contacts--list__item head">
						<div></div>
						<p class="info__address">Name</p>
						<p class="info__address">Investor Name</p>
						<p class="info__address">Amount</p>
						<p class="info__address">Balance</p>
						<p class="info__address">Status</p>
						<p class="info__address">Payment</p>
						<p class="info__address">Date</p>
					</div>
					<div class="contacts__body">
						<template v-if="mutualFundsLoaded && searchLoaded && mutualFunds.length > 0">
							<InvestorMutualFunds
								v-for="mutualFund in mutualFunds"
								:key="mutualFund.id"
								:mutualFundData="mutualFund"
							/>
						</template>
						<template v-else-if="!searchLoaded && getSearchInvestorMutualFunds.length > 0">
							<InvestorMutualFunds
								v-for="mutualFund in getSearchInvestorMutualFunds"
								:key="mutualFund.id"
								:mutualFundData="mutualFund"
							/>
						</template>
						<template v-else-if="!searchLoaded && getSearchInvestorMutualFunds.length === 0">
							<p class="mt-5 pl-5">No Investor Mutual Fund found</p>
						</template>
						<template v-else-if="mutualFundsLoaded">
							<p class="mt-5 pl-5">No Investor Mutual Fund found</p>
						</template>
						<template v-else>
							<p class="alert alert-danger">
								An error occurred while loading mutualFunds, please try again.
							</p>
						</template>
					</div>
				</div>
			</section>
			<Pagination :options="{ limit, offset, total, baseUrl }" v-if="total > limit" />
		</template>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Loader from '../../components/Loader';
import Pagination from '../../components/Pagination';
import InvestorMutualFunds from './InvestorMutualFunds';

export default {
	name: 'InvestorMutualFund',
	components: {
		Loader,
		Pagination,
		InvestorMutualFunds
	},
	data() {
		return {
			mutualFundsLoaded: false,
			loadingInvestorMutualFunds: true,
			offset: 0,
			limit: 10,
			baseUrl: 'investor-mf',
			query: '',
			searchLoaded: true,
			total: 0
		};
	},
	created() {
		this.limit = this.$route.query.limit ? this.$route.query.limit : this.limit;
		this.offset =
			this.$route.query.offset || this.$route.query.offset === 0 ? this.$route.query.offset : this.offset;
		this.fetchAllInvestorMutualFunds([this.limit, this.offset]);
	},
	watch: {
		status(value) {
			if (value === 'success') {
				this.loading = false;
				this.loadingInvestorMutualFunds = false;
				this.query = '';
				this.mutualFundsLoaded = true;
				this.total = this.searchLoaded ? this.totalInvestorMutualFunds : this.searchTotal;
			}
		},
		$route() {
			this.limit = this.$route.query.limit ? this.$route.query.limit : 10;
			this.offset = this.$route.query.offset ? this.$route.query.offset : 0;
			this.fetchAllInvestorMutualFunds([this.limit, this.offset]);
		}
	},
	computed: {
		...mapGetters({
			mutualFunds: 'getInvestorMutualFunds',
			status: 'getStatus',
			totalInvestorMutualFunds: 'getInvestorMutualFundsTotal'
		})
	},
	methods: {
		...mapActions(['fetchAllInvestorMutualFunds', 'searchOrders'])
	}
};
</script>

<style lang="scss" scoped>
.contacts--list__item {
	display: grid;
	grid-template-columns: 20px 1.7fr 1.2fr 0.7fr 0.7fr 0.7fr 0.8fr 1.5fr 0.5fr !important;
}
</style>
