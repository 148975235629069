<template>
	<div>
		<template v-if="loading">
			<div class="div__loading">
				<Loader />
			</div>
		</template>
		<template v-else>
			<section class="main-grid__row user-profile">
				<div class="user-profile__card">
					<div class="user-profile__section">
						<h5 class="user-profile__heading">Basic Information</h5>

						<div class="user-profile__item">
							<p class="user-profile__section__title">Amount:</p>
							<p class="" id="orderAmount">{{ itemData.amount | formatCurrency(itemData.currency) }}</p>
						</div>

						<div class="user-profile__item">
							<p class="user-profile__section__title">Balance:</p>
							<p class="" id="orderBalance">{{ itemData.balance | formatCurrency(itemData.currency) }}</p>
						</div>

						<div class="user-profile__item">
							<p class="user-profile__section__title">Payment Method:</p>
							<p class="capital">
								{{ itemData.payment }}
							</p>
						</div>

						<div class="user-profile__item">
							<p class="user-profile__section__title">Status</p>
							<p v-if="itemData.is_paused === 0">
								Active
							</p>
							<p v-else>
								Inactive
							</p>
						</div>

						<div class="user-profile__item">
							<p class="user-profile__section__title">Created Date</p>
							<p id="date">
								{{ itemData.created_at | date }}
							</p>
						</div>

						<div class="user-profile__item">
							<p class="user-profile__section__title">Withdrawal Date</p>
							<p id="date">
								{{ itemData.withdraw_date | date }}
							</p>
						</div>
					</div>
				</div>
				<div class="user-profile__tabs" ref="tab">
					<ul class="nav nav-tabs" id="myTab" role="tablist">
						<li class="nav-item">
							<a
								class="nav-link active"
								id="profile-tab"
								data-toggle="tab"
								href="#profile"
								role="tab"
								aria-controls="profile"
								aria-selected="true"
								>Details</a
							>
						</li>

						<li class="nav-item">
							<a
								class="nav-link "
								id="topups-tab"
								data-toggle="tab"
								href="#topups"
								role="tab"
								aria-controls="topups"
								aria-selected="true"
								>Top Up History</a
							>
						</li>
					</ul>
					<div class="tab-content" id="myTabContent">
						<div
							class="tab-pane fade show active user-profile__tabs__section"
							id="profile"
							role="tabpanel"
							aria-labelledby="profile-tab"
						>
							<div class="settings">
								<form>
									<div class="settings__field">
										<div class="form-row">
											<div class="form-group col-md-12">
												<div class="settings__value">
													<label for="buyer">Investment Owner Name</label>
													<input
														@keydown="clearErrors($event.target.name)"
														v-model="fullName"
														name="owner"
														type="text"
														class="form-control capital"
														id="owner"
														disabled
													/>
												</div>
											</div>
											<div class="form-group col-md-12">
												<div class="settings__value">
													<label for="nam">Investment Title</label>
													<input
														:class="[{ 'is-invalid': errors.buyer }]"
														@keydown="clearErrors($event.target.name)"
														v-model="itemData.mutualFund.title"
														name="name"
														type="text"
														class="form-control"
														id="buyer"
														disabled
													/>
												</div>
											</div>
											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="investorMutualFund_appreciation"
														>Investment Appreciation</label
													>
													<p>
														{{
															itemData.fund_appreciation
																| formatCurrency(itemData.currency)
														}}
													</p>
												</div>
											</div>
											<div class="form-group col-md-6" v-if="itemData.is_recurrent === 1">
												<div class="settings__value">
													<label for="type">Investment Type</label>
													<div class="settings__value">
														<div class="badge badge-pill badge-success">
															<span>Automated</span>
														</div>
														
													</div>
												</div>
											</div>
											<div class="form-group col-md-6" v-else>
												<div class="settings__value">
													<label for="type">Investment Type</label>
													<div class="settings__value">
														<div class="badge badge-pill badge-info">
															<span>Non Automated</span>
														</div>
													</div>
												</div>
											</div>

											<div class="form-group col-md-6" v-if="itemData.purchase_cycle !== null">
												<div class="settings__value">
													<label for="cycle">Investment Cycle</label>
													<p>{{ itemData.purchase_cycle }}</p>
												</div>
											</div>

											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="duratuion">Investment Duration</label>
													<p>{{ itemData.duration }} Months</p>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>

						<div
							class="tab-pane fade show active user-profile__tabs__section"
							id="topups"
							role="tabpanel"
							aria-labelledby="topups-tab"
						>

						<table class="table table-striped" v-if="itemData.mutualFundTopUps && itemData.mutualFundTopUps.length">
							<thead>
								<tr>
									<th>Amount</th>
									<th style="min-width: 150px">Payment</th>
									<th style="min-width: 150px">Balance Before</th>
									<th style="min-width: 150px">Balance After</th>
									<th>Date</th>
								</tr>
							</thead>

							<tbody>
								<template v-for="item in itemData.mutualFundTopUps">
									<tr :key="item.id">
										<td>{{itemData.mutualFund.currency}} {{item.amount}}</td>
										<td>{{item.payment}}</td>
										<td>{{itemData.mutualFund.currency}} {{item.balance_before}}</td>
										<td>{{itemData.mutualFund.currency}} {{item.balance_after}}</td>
										<td>{{item.created_at}}</td>
									</tr>
								</template>
							</tbody>
						</table>

						<div class="center" v-else>
							<p>No Wallet top up history</p>
						</div>
						</div>
					</div>
				</div>
			</section>
		</template>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import form from '../../mixins/form';
import Loader from '../../components/Loader';

export default {
	name: 'InvestorMutualFundDetails',
	mixins: [form],
	data() {
		return {
			itemData: {},
			errors: {},
			loading: true
		};
	},
	created() {
		const data = {
			userId: this.$route.params.userId,
			mutualFundId: this.$route.params.id
		};
		this.fetchInvestorMutualFundsDetail(data);
	},
	watch: {
		getStatus(value) {
			if (value === 'success') {
				this.itemData = this.getInvestorMutualFundsDetail;
				this.loading = false;
			}
		}
	},
	computed: {
		...mapGetters(['getStatus', 'getInvestorMutualFundsDetail']),
		fullName() {
			return `${this.itemData.user.firstname} ${this.itemData.user.lastname}`;
		}
	},
	methods: {
		...mapActions(['fetchInvestorMutualFundsDetail'])
	},
	components: {
		Loader
	},
	filters: {
		date(val) {
			return moment(val).format('LLLL');
		}
	}
};
</script>

<style lang="scss" scoped>
.settings {
	&--wide {
		max-width: 100%;
	}
	&__field {
		margin-top: 30px;
	}
	&__value {
		padding: 0;
		background: #fff;
		input {
			background: #fafafa;
			border: none;
			font-size: 14px;
			height: 36px;
		}
		label {
			font-weight: bold;
			margin-bottom: 0;
		}
	}
	.row {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 2rem;
	}
}
.btn__wrapper {
	margin-top: 20px;
	.btn {
		&:not(:last-child) {
			margin-right: 10px;
		}
	}
}
.bold {
	font-weight: bold;
	font-size: 16px;
	margin-right: 5px;
}
.pad {
	padding: 5px 10px;
}

.capital {
	text-transform: capitalize;
}
</style>
