var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"contacts--list__item"},[_vm._m(0),_c('div',{staticClass:"contacts__info"},[_c('div',{staticClass:"info__primary mouse-pointer",on:{"click":function($event){return _vm.$router.push({
					name: 'investorMutualFunds-detail',
					params: {
						id: _vm.investorMutualFunds.mutualFund.id,
						userId: _vm.investorMutualFunds.user.id
					}
				})}}},[_c('p',{staticClass:"info__email capital"},[_vm._v(_vm._s(_vm.investorMutualFunds.mutualFund.title))])])]),_c('p',{staticClass:"info__email capital mouse-pointer",on:{"click":function($event){return _vm.$router.push({
					name: 'investor-profile',
					params: {
						id: _vm.investorMutualFunds.user.id
					}
				})}}},[_vm._v(_vm._s(_vm.fullName))]),_c('p',{staticClass:"info__address capital"},[_vm._v("\n\t\t\t"+_vm._s(_vm._f("formatCurrency")(_vm.investorMutualFunds.amount,_vm.investorMutualFunds.mutualFund.currency))+"\n\t\t")]),_c('p',{staticClass:"info__address capital"},[_vm._v("\n\t\t\t"+_vm._s(_vm._f("formatCurrency")(_vm.investorMutualFunds.balance,_vm.investorMutualFunds.mutualFund.currency))+"\n\t\t")]),(_vm.investorMutualFunds.is_paused === 1)?_c('p',{staticClass:"info__address capital"},[_vm._v("\n\t\t\tPaused\n\t\t")]):_c('p',{staticClass:"info__address capital"},[_vm._v("\n\t\t\tActive\n\t\t")]),_c('p',{staticClass:"info__address capital"},[_vm._v(_vm._s(_vm.investorMutualFunds.payment))]),_c('p',{staticClass:"info__address capital"},[_vm._v(_vm._s(_vm._f("date")(_vm.investorMutualFunds.updated_at)))])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"check"},[_c('input',{attrs:{"type":"checkbox"}}),_c('div',{staticClass:"check-mark"})])}]

export { render, staticRenderFns }