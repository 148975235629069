<template>
	<div>
		<div class="contacts--list__item">
			<label class="check">
				<input type="checkbox" />
				<div class="check-mark"></div>
			</label>
			<div class="contacts__info">
				<div class="info__primary mouse-pointer" @click="$router.push({
						name: 'investorMutualFunds-detail',
						params: {
							id: investorMutualFunds.mutualFund.id,
							userId: investorMutualFunds.user.id
						}
					})">
					<p class="info__email capital">{{ investorMutualFunds.mutualFund.title }}</p>
				</div>
			</div>
			<p class="info__email capital mouse-pointer" @click="$router.push({
						name: 'investor-profile',
						params: {
							id: investorMutualFunds.user.id
						}
					})">{{ fullName }}</p>
			<p class="info__address capital">
				{{ investorMutualFunds.amount | formatCurrency(investorMutualFunds.mutualFund.currency) }}
			</p>
			<p class="info__address capital">
				{{ investorMutualFunds.balance | formatCurrency(investorMutualFunds.mutualFund.currency) }}
			</p>
			<p class="info__address capital" v-if="investorMutualFunds.is_paused === 1">
				Paused
			</p>
			<p class="info__address capital" v-else>
				Active
			</p>
			<p class="info__address capital">{{ investorMutualFunds.payment }}</p>
			<p class="info__address capital">{{ investorMutualFunds.updated_at | date }}</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'InvestMutualFundItem',
	props: {
		mutualFundData: {
			type: Object,
			default() {
				return {};
			}
		}
	},
	data() {
		return {
			investorMutualFunds: this.mutualFundData,
			itemData: '',
			mapStatus: ''
		};
	},
	computed: {
		fullName() {
			return `${this.investorMutualFunds.user.firstname} ${this.investorMutualFunds.user.lastname}`;
		}
	},
	filters: {
		date(val) {
			return moment(val).format('llll');
		}
	}
};
</script>

<style scoped>
.badge {
	font-weight: normal;
}
.contacts--list__item {
	display: grid;
	grid-template-columns: 20px 1.9fr 1.2fr 1fr 1fr 0.7fr 0.5fr 1.5fr 0.5fr !important;
}
.info__primary {
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-right: 10px;
}
.info__email {
	text-transform: lowercase;
}
.contacts__info {
	overflow: hidden;
}
.capital {
	text-transform: capitalize;
}
</style>
